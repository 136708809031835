import React from 'react';
import './style.css';
import { useNavigate } from 'react-router-dom';

const list_menu = [{ name: 'Home', url: '/' }];

export default function MobileMenu(props: { onCloseMenu: Function }) {
  const navigate = useNavigate();
  return (
    <ul className="mobile-menu-container">
      {list_menu.map((item, index) => {
        return (
          <li
            key={index}
            className="mobile-menu-item"
            onClick={() => {
              navigate(item.url);
              window.scrollTo(0, 0);
              props.onCloseMenu();
            }}
          >
            {item.name}
          </li>
        );
      })}
    </ul>
  );
}
