import React from "react";
import HeaderDesktop from "../../components/HeaderDesktop";
import { useWindowWidth } from "../../breakpoints";
import LegalItem from "../Privacy/PrivacyItem";

import LEGAL_DATA from "../../utils/dummy_data";
import content from "bundle-text:../../utils/terms_content.txt";


import "./style.css";

export default function Term() {
  const screenWidth = useWindowWidth();

  return (
    <div className="term-page">
      {screenWidth >= 900 && <HeaderDesktop />}
      <div className="container my-[100px] mx-auto !items-start">
        <div dangerouslySetInnerHTML={{__html:content}}/>
      </div>
      {/* {screenWidth >= 900 && (
        <div className="main-banner">
          <div className="container-main">
            <div className="title-banner">
              <h2 className="sub-title">Privacy Term</h2>
              <h1 className="main-title">Terms and conditions</h1>
            </div>
            <p className="desc-title">
              Your privacy is important to us at Untitled. We respect your
              privacy regarding any information we may collect from you across
              our website.
            </p>
            <div className="list-button">
              <div className="default-btn">Monlthy billing</div>
              <div className="secondary-btn">Annual billing</div>
            </div>
          </div>
        </div>
      )}
      {screenWidth < 900 && (
        <div className="mobile-main-banner">
          <div className="mobile-title-banner">
            <h2 className="mobile-sub-title">Current as of 20 Jan 2022</h2>
            <h1 className="mobile-main-title">Cookie Term</h1>
          </div>
          <p className="mobile-desc-title">
            We use some cookies to help improve your experience on this website.
            Here's some more info.
          </p>
          <div className="mobile-list-button">
            <div className="mobile-default-btn">Monlthy billing</div>
            <div className="mobile-secondary-btn">Annual billing</div>
          </div>
        </div>
      )}
      <div className="main-content">
        <p className="desc-content">
          Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam
          suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis
          montes, sit sit. Tellus aliquam enim urna, etiam. Mauris posuere
          vulputate arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien
          varius id. Eget quis mi enim, leo lacinia pharetra, semper. Eget in
          volutpat mollis at volutpat lectus velit, sed auctor. Porttitor fames
          arcu quis fusce augue enim. Quis at habitant diam at. Suscipit
          tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum
          molestie aliquet sodales id est ac volutpat.{" "}
        </p>
        {LEGAL_DATA.map((privacy) => (
          <LegalItem {...privacy} />
        ))}
      </div>
      <div className="section-12">
        <div className="container-6">
          <div className="content-8">
            <div className="heading-and">
              <div className="heading-2">Join 2,000+ subscribers</div>
              <p className="supporting-text-3">
                Stay in the loop with everything you need to know.
              </p>
            </div>
            <div className="div-7">
              <div className="input-field-base-wrapper">
                <div className="div-3">
                  <div className="div-5">
                    <div className="input-3">
                      <input
                        className="content-3"
                        placeholder="Enter your email"
                        type="email"
                      />
                    </div>
                  </div>
                  <p className="hint-text">
                    <span className="text-wrapper-5">
                      We care about your data in our{" "}
                    </span>
                    <span className="text-wrapper-4">privacy term</span>
                  </p>
                </div>
              </div>
              <button className="button-6">
                <button className="button-base-6">
                  <div className="text-4">Subscribe</div>
                </button>
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
