import axios from 'axios';
import { serverConfig } from '../../config';

const API_URL = serverConfig.api;

const defaultOptions = {};

function getNotAuthApi(path: string, options: any = {}, apiURL?: string) {
  return axios.get(`${apiURL || API_URL}/${path.replace(/ ^\//, '')}`, {
    ...defaultOptions,
    ...options,
    headers: {
      ...options.headers
    }
  });
}

function getApi(path: string, options: any = {}, apiURL?: string, token?: string) {
  return axios.get(`${apiURL || API_URL}/${path.replace(/^\//, '')}`, {
    ...defaultOptions,
    ...options,
    headers: {
      ...options.headers,
    }
  });
}

function getApiWithParams(path: string, params: object, options: any = {}, apiURL?: string, token?: string) {
  return axios.get(`${apiURL || API_URL}/${path.replace(/^\//, '')}`, {
    ...defaultOptions,
    ...options,
    params: params,
    headers: {
      ...options.headers,
    }
  });
}

function postApi(path: string, data: any, options: any = {}, token?: string) {
  return axios.post(`${API_URL}/${path.replace(/^\//, '')}`, data, {
    ...defaultOptions,
    ...options,
    headers: {
      ...options.headers,
    }
  });
}

function postApiNonAuth(path: string, data: any, options: any = {}) {
  return axios.post(`${API_URL}/${path.replace(/^\//, '')}`, data, {
    ...defaultOptions,
    ...options,
    headers: {
      ...options.headers
    }
  });
}

function putApiWithData(path: string, data: any, options: any = {}, token?: string) {
  return axios.put(`${API_URL}/${path.replace(/^\//, '')}`, data, {
    ...defaultOptions,
    ...options,
    headers: {
      ...options.headers,
    }
  });
}

function putApi(path: string, options: any = {}, token?: string) {
  return axios.put(`${API_URL}/${path.replace(/^\//, '')}`,null, {
    ...defaultOptions,
    ...options,
    headers: {
      ...options.headers,
    },
  });
}

function putApiWithParams(path: string, data: any, options: any = {}, token?: string) {
  return axios.put(`${API_URL}/${path.replace(/^\//, '')}`, data, {
    ...defaultOptions,
    ...options,
    headers: {
      ...options.headers,
    }
  });
}

function patchApi(path: string, data: any, options: any = {}, token?: string) {
  return axios.patch(`${API_URL}/${path.replace(/^\//, '')}`, data, {
    ...defaultOptions,
    ...options,
    headers: {
      ...options.headers,
    }
  });
}

function deleteApi(path: string, options: any = {}, token?: string) {
  return axios.delete(`${API_URL}/${path.replace(/^\//, '')}`, {
    ...defaultOptions,
    ...options,
    headers: {
      ...options.headers,
    }
  });
}

function deleteApiWithData(path: string,data:any, options: any = {}, token?: string) {
  return axios.delete(`${API_URL}/${path.replace(/^\//, '')}`, {
    ...defaultOptions,
    ...options,
    data: {
      ...data
    },
    headers: {
      ...options.headers,
    }
  });
}

function deleteWithParams(path: string, data: any, options: any = {}, token?: string) {
  return axios.delete(`${API_URL}/${path.replace(/^\//, '')}`, {
    ...defaultOptions,
    ...options,
    params: {
      ...data
    },
    headers: {
      ...options.headers,
    }
  });
}

function handleErrorStatus(error: any) {
  const status = error?.status || error?.response?.status || error?.data?.messages || null;
  switch (status) {
    case 401:
      return error;
    case 403: {
      // Router.push('/forbidden');
      return error;
    }
    case 404:
      return error;
    case 200:
    case 201:
    case 204:
    case 400:
    case 422:
      return error;
    case 500:
      return error;
    default:
      return error;
  }
}

axios.interceptors.response.use(
  (response) => {
    let data = response?.data;
    return handleErrorStatus({ ...response, data });
  },
  (error) => {
    const errorResponse = error.response;

    return Promise.reject(handleErrorStatus(errorResponse));
  }
);

axios.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };
    if (newConfig.headers && newConfig.headers['Content-Type'] === 'multipart/form-data') return newConfig;
    return newConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const Api = {
  get: getApi,
  post: postApi,
  postApiNonAuth: postApiNonAuth,
  putData: putApiWithData,
  put: putApi,
  delete: deleteApi,
  patch: patchApi,
  getNotAuth: getNotAuthApi,
  getWithParams: getApiWithParams,
  putWithParams: putApiWithParams,
  deleteWithParams: deleteWithParams,
  deleteWithDatas: deleteApiWithData
};

export default Api;
