export const TIME_FORMAT = {
    SHORT_TIME: 'hh:mm A',
    LONG_TIME: 'HH:mm:ss',
    LONG_DATE: 'DD/MM/YYYY',
    YEAR_DATE_HOUR: 'YYYY-MM-DD HH:mm',
    DATE_MONTH_YEAR_HOUR: "YYYY-MM-DD'T'HH:mm:ss.SSS'Z'",
    YEAR_MONTH: 'YYYY/MM',
    YEAR_MONTH_DAY: 'YYYY/MM/DD',
    DAYS: 'dddd',
    MONTH_YEAR: 'MM/YYYY',
    YEAR_MONTH_DATE: 'YYYY-MM-DD',
    MONTH_DATE_YEAR: 'MMM D, YYYY',
    DATE_MONTH_YEAR_HOUR_STR: 'YYYYMMDDTHHmmssZ'
  };
  
  export const DATE_FORMAT = {
    DATE: 'yyyy-MM-dd',
    EN_DAY_MONTH_YEAR: 'DD/MM/YYYY',
    EN_DAY_MONTH_NAME_YEAR: 'DD MMMM YYYY',
    EN_YEAR_MONTH_DAY: 'YYYY/MM/DD',
    HOURS: 'HH:mm',
    DAY: 'dddd'
  };