import React from 'react';
import { useNavigate } from 'react-router-dom';

import union8 from '../../../static/img/union-8.svg';
import social_icon from '../../../static/img/social-icon.svg';
import social_icon_1 from '../../../static/img/social-icon-1.svg';
import social_icon_2 from '../../../static/img/social-icon-2.svg';
import social_icon_3 from '../../../static/img/social-icon-3.svg';

export default function FooterDesktop() {
  const navigate = useNavigate();

  return (
    <footer className="footer-2">
      <div className="container-7">
        <div className="div-8">
          <div className="logo-and-links">
            <div className="logo-and-supporting">
              <div className="frame-21">
                <div className="overlap-group-3">
                  <div className="text-wrapper-6">™</div>
                  <div className="text-wrapper-7">kumu</div>
                </div>
                <img className="union-5" alt="Union" src={union8} />
              </div>
              <p className="supporting-text-4">Made with ♥️ by FastStartup</p>
            </div>
          </div>
          <div className="frame-43">
            <div className="social-icons">
              <img className="img-2" alt="Social icon" src={social_icon} />
              <img className="img-2" alt="Social icon" src={social_icon_2} />
              <img className="img-2" alt="Social icon" src={social_icon_1} />
              <div className="social-icon-2" />
              <img className="img-2" alt="Social icon" src={social_icon_3} />
            </div>
            <div className="button-5">
              <div className="primary-6">
                <div className="title-19">Get Started</div>
              </div>
              <div className="seconday-4">
                <div className="title-18">Apply as a Coach</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-8">
        <div className="divider" />
        <div className="content-9">
          <p className="footer-text-2">© 2024 CoachConnect, Inc.</p>
          <div className="footer-links-2">
            <div
              className="footer-link-3"
              onClick={() => {
                navigate('/term');
                window.scrollTo(0, 0);
              }}
            >
              Terms
            </div>
            <div
              className="footer-link-3"
              onClick={() => {
                navigate('/privacy');
                window.scrollTo(0, 0);
              }}
            >
              Privacy
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
