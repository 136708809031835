import { useQuery } from "@tanstack/react-query"
import Api from "./config/axios.Config"
import { URL_LESSONS } from "./config/endpoint"
import { ILessonsFeed } from "../types/lessons-feed"

export const useLessonsFeedQuery=()=>{
    return useQuery(
        ['get_lessons_feed'],
        async(): Promise<ILessonsFeed[]> => {
            try {
                const { data } = await Api.getNotAuth(`${URL_LESSONS}/feed`);
                return data.items.map((item: ILessonsFeed) => {
                    return {
                        ...item
                    }
                })
            } catch (err) {
                throw err;
            }
        }
    )
}