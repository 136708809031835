import React, { useState } from "react";
import { useWindowWidth } from "../../breakpoints";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import union_10 from "../../../static/img/union-10.svg";
import vuesax_linear_menu from "../../../static/img/vuesax-linear-menu.svg";
import icon_close from "../../../static/img/ico-xmark-solid.svg";
import social_icon_4 from "../../../static/img/social-icon-4.svg";
import social_icon_5 from "../../../static/img/social-icon-5.svg";
import social_icon_6 from "../../../static/img/social-icon-6.svg";
import social_icon_7 from "../../../static/img/social-icon-7.svg";
import union_14 from "../../../static/img/union-14.svg";
import FooterDesktop from "../FooterDesktop";
import MobileMenu from "./MobileMenu";

export default function DefaultLayout() {
  const screenWidth = useWindowWidth();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const navigate = useNavigate();

  return screenWidth < 900 ? (
    <div className="home-desktop">
      <div className="overlap">
        <div className="frame">
          <div className="content">
            <div className="frame-2">
              <div className="overlap-group">
                <div className="text-wrapper">™</div>
                <div className="text-wrapper-2">kumu</div>
              </div>
              <img className="img" alt="Union" src={union_10} />
            </div>
            {!showMobileMenu && (
              <img
                className="img-2"
                alt="Vuesax linear menu"
                src={vuesax_linear_menu}
                onClick={() => setShowMobileMenu(true)}
              />
            )}
            {showMobileMenu && (
              <img
                className="img-2"
                alt="icon_close"
                src={icon_close}
                onClick={() => setShowMobileMenu(false)}
              />
            )}
          </div>
          {showMobileMenu && (
            <MobileMenu
              onCloseMenu={() => {
                setShowMobileMenu(false);
              }}
            />
          )}
          {!showMobileMenu && (
            <>
              <Outlet />
              <footer className="footer">
                <div className="div-4">
                  <div className="div-4">
                    <div className="logo-and-supporting">
                      <div className="frame-21">
                        <div className="overlap-group-3">
                          <div className="text-wrapper-6">™</div>
                          <div className="text-wrapper-7">kumu</div>
                        </div>
                        <img className="union-5" alt="Union" src={union_14} />
                      </div>
                      <p className="supporting-text-2">
                        Lorem ipsum dolor sit amet consectetur. Nibh in
                        ultricies hendrerit arcu. Eleifend.
                      </p>
                    </div>
                    <div className="footer-links">
                      <div className="footer-link">
                        <button className="button-2">
                          <div className="button-base-3">
                            <div className="text-5">Key Features</div>
                          </div>
                        </button>
                      </div>
                      <div className="footer-link">
                        <button className="button-2">
                          <div className="button-base-3">
                            <div className="text-6">How it works</div>
                          </div>
                        </button>
                      </div>
                      <div className="footer-link">
                        <button className="button-2">
                          <div className="button-base-3">
                            <div className="text-6">Pricing</div>
                          </div>
                        </button>
                      </div>
                      <div className="footer-link">
                        <button className="button-2">
                          <div className="button-base-3">
                            <div className="text-6">About us</div>
                          </div>
                        </button>
                      </div>
                      <button className="button-wrapper">
                        <button className="button-2">
                          <button className="button-base-4">
                            <div className="text-6">Contact Us</div>
                          </button>
                        </button>
                      </button>
                      <div className="footer-link">
                        <div className="button-3">
                          <div className="button-base-3" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-4">
                    <div className="social-icons">
                      <img
                        className="img-2"
                        alt="Social icon"
                        src={social_icon_4}
                      />
                      <img
                        className="img-2"
                        alt="Social icon"
                        src={social_icon_6}
                      />
                      <img
                        className="img-2"
                        alt="Social icon"
                        src={social_icon_5}
                      />
                      <div className="social-icon" />
                      <img
                        className="img-2"
                        alt="Social icon"
                        src={social_icon_7}
                      />
                    </div>
                    <div className="button-4">
                      <div className="primary-3">
                        <div className="title-17">Get Started</div>
                      </div>
                      <div className="seconday-2">
                        <div className="title-18">Apply as a Coach</div>
                      </div>
                      <div className="container-2">
                        <div className="divider" />
                        <div className="content-4">
                          <p className="footer-text">
                            © 2024 Kumuapp. All rights reserved.
                          </p>
                          <div className="div-7">
                            <div
                              className="footer-link-2"
                              onClick={() => {
                                navigate("/term");
                                window.scrollTo(0, 0);
                              }}
                            >
                              Terms
                            </div>
                            <div
                              className="footer-link-2"
                              onClick={() => {
                                navigate("/privacy");
                                window.scrollTo(0, 0);
                              }}
                            >
                              Privacy
                            </div>
                            <div className="footer-link-2">Cookies</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="home-desktop">
      <Outlet />
      <FooterDesktop />
    </div>
  );
}
