import React from 'react';
import { useNavigate } from 'react-router-dom';
import union2 from '../../../static/img/union-2.svg';

export default function HeaderDesktop() {
  const navigate = useNavigate();

  // Determine the base URL based on the APP_ENVIRONMENT
  const baseUrl =
    process.env.APP === 'production'
      ? 'https://auth.kumuapp.io'
      : 'https://auth.develop.kumuapp.io';

  // Function to handle login navigation
  const handleLogin = () => {
    window.location.href = `${baseUrl}/signin`;
  };

  // Function to handle sign up navigation
  const handleSignUp = () => {
    window.location.href = `${baseUrl}/register`;
  };

  return (
    <div className="top-bar">
      <div className="frame-22" onClick={() => navigate('/')}>
        <div className="overlap-group-5">
          <div className="text-wrapper-8">™</div>
          <div className="text-wrapper-9">kumu</div>
        </div>
        <img className="union-8" alt="Union" src={union2} />
      </div>

      <div className="frame-23">
        <div className="frame-24">
          <div className="primary-4" onClick={handleLogin}>
            <div className="title-19">Login</div>
          </div>
          <div className="primary-5" onClick={handleSignUp}>
            <div className="title-19">Sign Up</div>
          </div>
        </div>
      </div>
    </div>
  );
}
