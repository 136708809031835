import React from "react";
import HeaderDesktop from "../../components/HeaderDesktop";
import { useWindowWidth } from "../../breakpoints";
import LegalItem from "./PrivacyItem";

import fits_2 from "../../../static/img/fits-2.png";

import "./style.css";
import LEGAL_DATA from "../../utils/dummy_data";
import fs from "fs";
import path from "path";
import content from "bundle-text:../../utils/privacy_content.txt";

export default function Privacy() {
  const screenWidth = useWindowWidth();

  return (
    <div className="privacy-page">
      {screenWidth >= 900 && <HeaderDesktop />}
      <div className="container my-[100px] mx-auto !items-start">
        <div dangerouslySetInnerHTML={{__html:content}}/>
      </div>
    </div>
  );
}
