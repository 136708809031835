import React, { useState } from "react";
import union1 from "../../../static/img/union-1.svg";
import union3 from "../../../static/img/union-3.svg";
import union4 from "../../../static/img/union-4.svg";
import union6 from "../../../static/img/union-6.png";

import union9 from "../../../static/img/union-9.svg";
import untitled_1_1 from "../../../static/img/untitled-1-1.png";
import chevron_down from "../../../static/img/chevron-down.svg";
import search_normal from "../../../static/img/vuesax-linear-search-normal.svg";
import rate from "../../../static/img/frame-427318817.svg";
import linear_calendar from "../../../static/img/vuesax-linear-calendar.png";
import linear_timer from "../../../static/img/vuesax-linear-timer.svg";
import linear_calendar_1 from "../../../static/img/vuesax-linear-calendar-1.svg";
import linear_clock_1 from "../../../static/img/vuesax-linear-clock-1.svg";
import linear_timer_1 from "../../../static/img/vuesax-linear-timer-1.svg";
import linear_location_1 from "../../../static/img/vuesax-linear-location-1.svg";
import linear_calendar_tick from "../../../static/img/vuesax-linear-calendar-tick.svg";
import linear_tag_user from "../../../static/img/vuesax-linear-tag-user.svg";
import linear_calendar_5 from "../../../static/img/vuesax-linear-calendar-5.svg";
import vuesax_linear_coin from "../../../static/img/vuesax-linear-coin.svg";
import vuesax_linear_star from "../../../static/img/vuesax-linear-star.svg";
import mask_group from "../../../static/img/mask-group.png";
import rectangle_4469 from "../../../static/img/rectangle-4469.png";
import frame_1000003466 from "../../../static/img/frame-1000003466.svg";
import vector_601 from "../../../static/img/vector-601.svg";
import fits_2 from "../../../static/img/fits-2.png";
import vuesax_linear_cup from "../../../static/img/vuesax-linear-cup.svg";
import vuesax_linear_calendar_6 from "../../../static/img/vuesax-linear-calendar-6.svg";
import vuesax_linear_user_octagon from "../../../static/img/vuesax-linear-user-octagon.svg";
import frame_427318817_8 from "../../../static/img/frame-427318817-8.svg";
import us_divided from "../../../static/img/us-divided.svg";
import HeaderDesktop from "../../components/HeaderDesktop";
import { ILessonsFeed } from "../../types/lessons-feed";
import { useLessonsFeedQuery } from "../../api/useLessons";
import { TIME_FORMAT } from "../../constants/common";
import moment from "moment";
import { convertToHourMinute } from "../../utils/duration";
import Slider from "react-slick";
import avatar_default from "../../../static/img/avatars-default.png";
import { getLabelForSuggestedLocation } from "../../utils/lacation";
import ic_star from "../../../static/img/ic-star.svg";
import ic_half_star from "../../../static/img/ic-half-star.svg";
import default_img from "../../../static/img/default_large.png";

export default function HomeDesktop() {
  const { data: lessonsFeedList, isLoading } = useLessonsFeedQuery();

  const settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: false,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
    ],
  };

  const stars = [
    <img key={Math.random()} src={ic_star} alt="ic-star" width={9.81} />,
    <img key={Math.random()} src={ic_star} alt="ic-star" width={9.81} />,
    <img key={Math.random()} src={ic_star} alt="ic-star" width={9.81} />,
    <img key={Math.random()} src={ic_star} alt="ic-star" width={9.81} />,
    <img key={Math.random()} src={ic_star} alt="ic-star" width={9.81} />,
  ];

  // Determine the base URL based on the APP_ENVIRONMENT
  const baseUrl =
    process.env.APP === "production"
      ? "https://auth.kumuapp.io"
      : "https://auth.develop.kumuapp.io";

  // Function to handle login navigation
  const handleLogin = () => {
    window.location.href = `${baseUrl}/signin`;
  };

  // Function to handle sign up navigation
  const handleSignUp = () => {
    window.location.href = `${baseUrl}/register`;
  };

  return (
    <>
      <div className="overlap-wrapper">
        <div className="overlap-2">
          <div className="overlap-3">
            <div className="graphic-element">
              <div className="overlap-group-4">
                <img className="union-6" alt="Union" src={union9} />
                <img className="untitled-2" alt="Untitled" src={untitled_1_1} />
                <img className="union-7" alt="Union" src={union1} />
              </div>
            </div>
            <HeaderDesktop />
          </div>
          <div className="container-3">
            <p className="title-20">
              Book your lesson today with expert coaches
            </p>
            <p className="text-wrapper-10">
              Find the perfect coach for your needs.
            </p>
            <div className="button-5">
              <div className="primary-6" onClick={handleLogin}>
                <div className="title-19">Get Started</div>
              </div>
              <div className="seconday-3" onClick={handleSignUp}>
                <div className="title-19">Apply as a Coach</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-5">
        <div className="frame-25">
          <div className="airbnb-search-3">
            <div className="attribute">
              <div className="attribute-2">Search</div>
              <p className="value">Search by sport, coach, level...</p>
            </div>
          </div>
          <div className="airbnb-search-3">
            <div className="attribute">
              <div className="attribute-2">Location</div>
              <p className="value">Where are you based ?</p>
            </div>
            <img className="img-2" alt="Chevron down" src={chevron_down} />
          </div>
          <div className="airbnb-search-3">
            <div className="attribute">
              <div className="attribute-2">Sport</div>
              <div className="value">Choose your sports</div>
            </div>
            <img className="img-2" alt="Chevron down" src={chevron_down} />
          </div>
          <div className="primary-7">
            <img
              className="img-2"
              alt="Vuesax linear search"
              src={search_normal}
            />
            <div className="title-21">Search</div>
          </div>
        </div>
      </div>
      <Slider {...settings}>
        {lessonsFeedList?.map((lessonFeed: ILessonsFeed) => {
          return (
            // <div className="row-wrapper" key={lessonFeed.id}>
            // <div className="row">
            <div className="card" key={lessonFeed.id}>
              <div className="image-wrapper">
                <img
                  src={lessonFeed.image || default_img}
                  alt="image"
                  className="image-4"
                />
                <div className="frame-27">
                  <div className="title-22">${lessonFeed.defaultPrice}</div>
                </div>
              </div>
              <div className="text-content">
                <p className="subtitle-8">{lessonFeed.title}</p>
                <div className="user">
                  <div className="avatar">
                    {/* <div className="avatar-2" /> */}
                    <img
                      src={lessonFeed.coach?.avatar || avatar_default}
                      alt="avatar"
                      className="avatar-2"
                    />
                    <div className="div-wrapper">
                      <div className="title-23">{`${lessonFeed.coach.firstName} ${lessonFeed.coach.lastName}`}</div>
                    </div>
                  </div>
                  {lessonFeed.coach.totalRating > 0 && (
                    <div style={{ display: "flex", gap: "2px" }}>
                      {lessonFeed.coach.totalRating % 1 !== 0.5
                        ? stars.slice(-lessonFeed.coach.totalRating)
                        : stars
                            .slice(-lessonFeed.coach.totalRating)
                            .concat(
                              <img
                                key={Math.random()}
                                src={ic_half_star}
                                alt="ic-half-star"
                                width={9.81}
                              />
                            )}
                    </div>
                  )}
                </div>
                <div className="frame-6">
                  <img
                    className="img-3"
                    alt="Vuesax linear"
                    src={linear_calendar_1}
                  />
                  <div className="title-24">
                    {moment(lessonFeed.startsAt).format(
                      TIME_FORMAT.MONTH_DATE_YEAR
                    )}
                  </div>
                  <img
                    className="img-3"
                    alt="Vuesax linear clock"
                    src={linear_clock_1}
                  />
                  <div className="title-24">
                    {moment(lessonFeed.startsAt).format(TIME_FORMAT.SHORT_TIME)}
                  </div>
                  <img
                    className="img-3"
                    alt="Vuesax linear timer"
                    src={linear_timer_1}
                  />
                  <div className="title-24">
                    {convertToHourMinute(lessonFeed.duration)}
                  </div>
                </div>
                <div className="frame-6">
                  <img
                    className="img-3"
                    alt="Vuesax linear"
                    src={linear_location_1}
                  />
                  <p className="title-24">
                    {getLabelForSuggestedLocation(lessonFeed.location.name)}
                  </p>
                </div>
              </div>
            </div>
            // </div>
            // </div>
          );
        })}
      </Slider>
      <div className="frame-26"></div>
      <div className="section-6">
        <img className="union-9" alt="Union" src={union3} />
        <div className="frame-30">
          <div className="frame-31">
            <div className="title-25">Key Features</div>
          </div>
          <div className="frame-9">
            <div className="item-3">
              <div className="vuesax-linear-wrapper">
                <img
                  className="img-2"
                  alt="Vuesax linear"
                  src={linear_calendar_tick}
                />
              </div>
              <div className="frame-32">
                <div className="title-13">Lesson Scheduling</div>
                <p className="text-wrapper-10">
                  Kumu provides coaches with a flexible and intuitive platform
                  to easily set up, track, and adjust their lesson schedules.
                </p>
              </div>
            </div>
            <div className="item-4">
              <div className="img-wrapper">
                <img
                  className="img-2"
                  alt="Vuesax linear tag"
                  src={linear_tag_user}
                />
              </div>
              <div className="frame-32">
                <div className="title-13">Client Marketplace</div>
                <p className="subtitle-3">
                  ∂ Athletes have the freedom to explore and connect with a wide
                  range of coaches, ensuring they find the perfect match.
                </p>
              </div>
            </div>
            <div className="item-5">
              <div className="img-wrapper">
                <img
                  className="img-2"
                  alt="Vuesax linear"
                  src={linear_calendar_5}
                />
              </div>
              <div className="frame-32">
                <div className="title-13">Shareable schedule</div>
                <p className="subtitle-3">
                  With just a few clicks, coaches can share their entire
                  schedule or specific sessions with athletes.
                </p>
              </div>
            </div>
            <div className="item-6">
              <div className="img-wrapper">
                <img
                  className="img-2"
                  alt="Vuesax linear coin"
                  src={vuesax_linear_coin}
                />
              </div>
              <div className="frame-32">
                <div className="title-13">Easy Payment</div>
                <p className="subtitle-3">
                  Our secure integration with Stripe allows for hassle-free
                  financial transactions, making lesson payments
                  straightforward.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="rectangle-3" />
      </div>
      <div className="section-7">
        <div className="frame-33">
          <div className="frame-34">
            <div className="overlap-group-6">
              <img className="union-10" alt="Union" src={union4} />
              <img className="mask-group" alt="Mask group" src={mask_group} />
              <img
                className="rectangle-4"
                alt="Rectangle"
                src={rectangle_4469}
              />
            </div>
          </div>
        </div>
        <img className="frame-35" alt="Frame" src={frame_1000003466} />

        <div className="container-4">
          <div className="title-26">How It Works</div>
          <div className="container-procedure">
            <div className="frame-36">
              <div className="article-2">
                <div className="text-wrapper-11">1</div>
                <img className="vector" alt="Vector" src={vector_601} />
                <div className="frame-10">
                  <div className="frame-13">
                    <div className="title-11">Client</div>
                  </div>
                  <div className="title-27">Client Registration</div>
                  <p className="text-wrapper-10">
                    Begin your Kumu experience by signing up. Customize your
                    profile to connect with the ideal coach for your learning
                    objectives.
                  </p>
                </div>
              </div>
              <div className="article-2">
                <div className="text-wrapper-11">2</div>
                <img className="vector" alt="Vector" src={vector_601} />
                <div className="frame-10">
                  <div className="frame-13">
                    <div className="title-11">Client</div>
                  </div>
                  <div className="title-27">Browse Marketplace</div>
                  <p className="text-wrapper-10">
                    Navigate through our extensive selection of coaches. Review
                    their schedules and expertise to find the right fit for you.
                  </p>
                </div>
              </div>
              <div className="article-2">
                <div className="text-wrapper-11">3</div>
                <img className="vector" alt="Vector" src={vector_601} />
                <div className="frame-10">
                  <div className="frame-13">
                    <div className="title-11">Client</div>
                  </div>
                  <div className="title-27">Submit Lesson Requests</div>
                  <p className="text-wrapper-10">
                    Easily request lessons with your preferred coach based on
                    their live availability, all from within the Kumu
                    Marketplace.
                  </p>
                </div>
              </div>
              <div className="article-2">
                <div className="text-wrapper-11">4</div>
                <img className="vector" alt="Vector" src={vector_601} />
                <div className="frame-10">
                  <div className="frame-13">
                    <div className="title-11">Client</div>
                  </div>
                  <div className="title-27">Reach Your Goals</div>
                  <p className="text-wrapper-10">
                    Maintain progress with your current coach or seek new
                    coaching options to achieve your personal milestones.
                  </p>
                </div>
              </div>
            </div>
            <div className="frame-37">
              <div className="article-2">
                <div className="text-wrapper-11">1</div>
                <img className="vector" alt="Vector" src={vector_601} />
                <div className="frame-10">
                  <div className="frame-38">
                    <div className="title-11-black">Coach</div>
                  </div>
                  <div className="title-27">Coach Registration</div>
                  <p className="text-wrapper-10">
                    Sign up to become part of the Kumu platform. It takes only a
                    few minutes to setup your profile to get started!
                  </p>
                </div>
              </div>
              <div className="article-2">
                <div className="text-wrapper-11">2</div>
                <img className="vector" alt="Vector" src={vector_601} />
                <div className="frame-10">
                  <div className="frame-38">
                    <div className="title-11-black">Coach</div>
                  </div>
                  <div className="title-27">Manage Schedule</div>
                  <p className="text-wrapper-10">
                    With Kumu, managing your schedule is straightforward. Keep
                    track of your appointments and availability at hand.
                  </p>
                </div>
              </div>
              <div className="article-2">
                <div className="text-wrapper-11">3</div>
                <img className="vector" alt="Vector" src={vector_601} />
                <div className="frame-10">
                  <div className="frame-38">
                    <div className="title-11-black">Coach</div>
                  </div>
                  <div className="title-27">Manage Lesson Requests</div>
                  <p className="text-wrapper-10">
                    Streamline your lesson planning by handling requests from
                    clients to organize your schedule.
                  </p>
                </div>
              </div>
              <div className="article-2">
                <div className="text-wrapper-11">4</div>
                <img className="vector" alt="Vector" src={vector_601} />
                <div className="frame-10">
                  <div className="frame-38">
                    <div className="title-11-black">Coach</div>
                  </div>
                  <div className="title-27">Focus on What Matters</div>
                  <p className="text-wrapper-10">
                    Kumu takes care of the administrative details. We empower
                    the coaches to help their clients reach their goals.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-8">
        <div className="image-8">
          <p className="title-28">
            Discover the benefits of personalized coaching
          </p>
          <div className="primary-8">
            <div className="title-19">Get Started</div>
          </div>
          <div className="pagination">
            <div className="rectangle-5" />
            <div className="rectangle-6" />
            <div className="rectangle-6" />
            <div className="rectangle-6" />
          </div>
        </div>
        <img className="fits" alt="Fits" src={fits_2} />
      </div>
      <div className="title-29">Amazing Perks</div>
      <div className="section-9">
        <img className="union-12" alt="Union" src={union6} />
        <div className="list-wrapper">
          <div className="list-2">
            <div className="frame-39">
              <div className="frame-17">
                <div className="featured-icon-2">
                  <img
                    className="img-4"
                    alt="Vuesax linear cup"
                    src={vuesax_linear_cup}
                  />
                </div>
                <div className="div-3">
                  <div className="title-30">Improve your game</div>
                  <p className="subtitle-3">
                    Unlock your full potential with personalized feedback and
                    expert advice. Our coaches are here to guide your growth and
                    development, ensuring you achieve excellence in your sport.
                  </p>
                </div>
              </div>
              <div className="frame-17">
                <div className="featured-icon-2">
                  <img
                    className="img-4"
                    alt="Vuesax linear"
                    src={vuesax_linear_calendar_6}
                  />
                </div>
                <div className="div-3">
                  <div className="title-30">Learn a new skill</div>
                  <p className="subtitle-3">
                    Dive into a new sport with confidence. Our seasoned
                    professionals provide the foundational skills and support
                    you need to start strong and keep progressing.
                  </p>
                </div>
              </div>
              <div className="frame-17">
                <div className="featured-icon-2">
                  <img
                    className="img-4"
                    alt="Vuesax linear user"
                    src={vuesax_linear_user_octagon}
                  />
                </div>
                <div className="div-3">
                  <p className="title-30">Get outside and stay active</p>
                  <p className="subtitle-3">
                    Embrace the great outdoors and boost your physical health.
                    Our platform encourages an active lifestyle, offering you
                    opportunities to engage in sports, meet new people, and
                    enjoy the benefits of staying physically active.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-10">
        <div className="container-5">
          <div className="title-25">Testimonials</div>
          <div className="frame-40">
            <div className="card-3">
              <div className="user">
                <div className="avatar">
                  <div className="avatar-8" />
                  <div className="div-wrapper">
                    <div className="title-31">John S.</div>
                  </div>
                </div>
                <img className="frame-5" alt="Frame" src={rate} />
              </div>
              <p className="title-32">
                I've been using Kumu for some time now to manage my coaching
                sessions, and it's significantly streamlined the way I organize
                my schedule and connect with Client. The platform makes it easy
                to set up and adjust my availability.
              </p>
            </div>
            <div className="card-3">
              <div className="user">
                <div className="avatar">
                  <div className="avatar-9" />
                  <div className="div-wrapper">
                    <div className="title-31">Frank F.</div>
                  </div>
                </div>
                <img className="frame-5" alt="Frame" src={rate} />
              </div>
              <p className="title-32">
                As a coach, finding a reliable way to manage payments and
                schedule lessons has always been a challenge. With Kumu, I've
                found a solution that takes care of both, and it's been a
                game-changer for my business.
              </p>
            </div>
            <div className="card-3">
              <div className="user">
                <div className="avatar">
                  <div className="avatar-10" />
                  <div className="div-wrapper">
                    <div className="title-31">Keresa Y.</div>
                  </div>
                </div>
                <img className="frame-5" alt="Frame" src={rate} />
              </div>
              <p className="title-32">
                I was initially hesitant to switch over to a new app for
                managing my coaching business, but Kumu has proven to be
                intuitive and efficient. The customer support team was also very
                helpful whenever I had questions.
              </p>
            </div>
            <div className="card-3">
              <div className="user">
                <div className="avatar">
                  <div className="avatar-11" />
                  <div className="div-wrapper">
                    <div className="title-31">Michel S.</div>
                  </div>
                </div>
                <img className="frame-41" alt="Frame" src={frame_427318817_8} />
              </div>
              <p className="title-32">
                Kumu has made the administrative part of coaching so much
                easier. I especially appreciate the feature that allows Client
                to book their own slots based on my availability. It saves me a
                lot of back-and-forth communication.
              </p>
            </div>
            <div className="card-4">
              <div className="user">
                <div className="avatar">
                  <div className="avatar-12" />
                  <div className="div-wrapper">
                    <div className="title-31">Samantha B.</div>
                  </div>
                </div>
                <img className="frame-5" alt="Frame" src={rate} />
              </div>
              <p className="title-32">
                The feature that allows me to create and use lesson templates
                has cut down my planning time significantly. I can focus more on
                coaching and less on admin work, thanks to Kumu.
              </p>
            </div>
            <div className="card-4">
              <div className="user">
                <div className="avatar">
                  <div className="avatar-13" />
                  <div className="div-wrapper">
                    <div className="title-31">David K.</div>
                  </div>
                </div>
                <img className="frame-5" alt="Frame" src={rate} />
              </div>
              <p className="title-32">
                The feature that allows me to create and use lesson templates
                has cut down my planning time significantly. I can focus more on
                coaching and less on admin work, thanks to Kumu.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section-11">
        <div className="frame-31">
          <div className="title-25">Get in touch</div>
          <p className="subtitle-10">
            We're here to help and answer any questions you might have.
          </p>
        </div>
        <div className="frame-42">
          <img className="US-divided-2" alt="Us divided" src={us_divided} />
          <div className="content-5">
            <div className="content-6">
              <div className="form">
                <div className="form-fields-2">
                  <div className="div-8">
                    <div className="div-wrapper">
                      <div className="div-5">
                        <div className="div-5">
                          <div className="label">First name</div>
                          <div className="input">
                            <input
                              className="content-7"
                              id="input-8"
                              placeholder="First name"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="div-wrapper">
                      <div className="div-5">
                        <div className="div-5">
                          <div className="label">Last name</div>
                          <div className="input">
                            <input
                              className="content-7"
                              id="input-10"
                              placeholder="Last name"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="input-field">
                    <div className="div-5">
                      <div className="div-5">
                        <div className="label">Email</div>
                        <div className="input">
                          <input
                            className="content-7"
                            id="input-12"
                            placeholder="Email"
                            type="email"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="textarea-input-field">
                    <div className="div-6">
                      <div className="div-6">
                        <div className="label-2">Message</div>
                        <textarea className="input-2" />
                      </div>
                    </div>
                  </div>
                  <div className="checkbox">
                    <div className="checkbox-2" />
                    <p className="text-2">
                      <span className="span">You agree to our friendly </span>
                      <span className="text-wrapper-4">Terms of Service.</span>
                      <span className="span">.</span>
                    </p>
                  </div>
                </div>
                <div className="actions">
                  <button className="button-base-wrapper">
                    <div className="button-base-5">
                      <div className="text-7">Send message</div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-12">
        <div className="container-6">
          <div className="content-8">
            <div className="heading-and">
              <div className="heading-2">Join 2,000+ subscribers</div>
              <p className="supporting-text-3">
                Stay in the loop with everything you need to know.
              </p>
            </div>
            <div className="div-7">
              <div className="input-field-base-wrapper">
                <div className="div-3">
                  <div className="div-5">
                    <div className="input-3">
                      <input
                        className="content-3"
                        placeholder="Enter your email"
                        type="email"
                      />
                    </div>
                  </div>
                  <p className="hint-text">
                    <span className="text-wrapper-5">
                      We care about your data in our{" "}
                    </span>
                    <span className="text-wrapper-4">Privacy Policy</span>.
                  </p>
                </div>
              </div>
              <button className="button-6">
                <button className="button-base-6">
                  <div className="text-4">Subscribe</div>
                </button>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
