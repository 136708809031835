import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./screens/Home/Home";
import DefaultLayout from "./components/DefaultLayout";
import Privacy from "./screens/Privacy";
import Term from "./screens/Term";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<DefaultLayout />}>
        <Route index element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/term" element={<Term />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
}
