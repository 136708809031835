import React from 'react';
// import dotenv from 'dotenv';
// dotenv.config();
import ReactDOMClient from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './app';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './styles/globals.css';

const app = document.getElementById('app');
const root = ReactDOMClient.createRoot(app);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});
root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
    </BrowserRouter>
  </QueryClientProvider>
);
