import React from "react";

import union_11 from "../../../static/img/union-11.svg";
import union_12 from "../../../static/img/union-12.svg";
import union_13 from "../../../static/img/union-13.png";
import chevron_down_2 from "../../../static/img/chevron-down-2.svg";
import linear_search_normal_1 from "../../../static/img/vuesax-linear-search-normal-1.svg";
import untitled_1 from "../../../static/img/untitled-1-1-1.png";
import vuesax_linear_calendar_7 from "../../../static/img/vuesax-linear-calendar-7.svg";
import vuesax_linear_clock_5 from "../../../static/img/vuesax-linear-clock-5.svg";
import vuesax_linear_timer_5 from "../../../static/img/vuesax-linear-timer-5.svg";
import vuesax_linear_location_5 from "../../../static/img/vuesax-linear-location-5.svg";
import vuesax_linear_calendar_tick_1 from "../../../static/img/vuesax-linear-calendar-tick-1.svg";
import vuesax_linear_tag_user_1 from "../../../static/img/vuesax-linear-tag-user-1.svg";
import vuesax_linear_calendar_10 from "../../../static/img/vuesax-linear-calendar-10.svg";
import vuesax_linear_coin_1 from "../../../static/img/vuesax-linear-coin-1.svg";
import vuesax_linear_star_1 from "../../../static/img/vuesax-linear-star-1.svg";
import vector_601_8 from "../../../static/img/vector-601-8.svg";
import rectangle_4469_1 from "../../../static/img/rectangle-4469-1.png";
import vuesax_linear_cup_1 from "../../../static/img/vuesax-linear-cup-1.svg";
import vuesax_linear_calendar_11 from "../../../static/img/vuesax-linear-calendar-11.svg";
import vuesax_linear_user_octagon_1 from "../../../static/img/vuesax-linear-user-octagon-1.svg";
import frame_427318817_15 from "../../../static/img/frame-427318817-15.svg";
import us_divided_1 from "../../../static/img/us-divided-1.svg";
import { ILessonsFeed } from "../../types/lessons-feed";
import { useLessonsFeedQuery } from "../../api/useLessons";
import { TIME_FORMAT } from "../../constants/common";
import moment from "moment";
import { convertToHourMinute } from "../../utils/duration";
import Slider from "react-slick";
import ic_star from "../../../static/img/ic-star.svg";
import ic_half_star from "../../../static/img/ic-half-star.svg";
import default_img from "../../../static/img/default_large.png";

export default function HomeMobile() {
  const { data: lessonsFeedList, isLoading } = useLessonsFeedQuery();

  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const stars = [
    <img key={Math.random()} src={ic_star} alt="ic-star" width={9.81} />,
    <img key={Math.random()} src={ic_star} alt="ic-star" width={9.81} />,
    <img key={Math.random()} src={ic_star} alt="ic-star" width={9.81} />,
    <img key={Math.random()} src={ic_star} alt="ic-star" width={9.81} />,
    <img key={Math.random()} src={ic_star} alt="ic-star" width={9.81} />,
  ];

  // Determine the base URL based on the APP_ENVIRONMENT
  const baseUrl =
    process.env.APP === "production"
      ? "https://auth.kumuapp.io"
      : "https://auth.develop.kumuapp.io";

  // Function to handle login navigation
  const handleLogin = () => {
    window.location.href = `${baseUrl}/signin`;
  };

  // Function to handle sign up navigation
  const handleSignUp = () => {
    window.location.href = `${baseUrl}/register`;
  };

  return (
    <>
      <div className="section">
        <div className="overlap-group-wrapper">
          <div className="overlap-group-2">
            <img className="union-2" alt="Union" src={union_11} />
            <img className="untitled" alt="Untitled" src={untitled_1} />
            <img className="union-3" alt="Union" src={union_12} />
          </div>
        </div>
        <div className="container">
          <p className="title">Book your lesson today with expert coaches</p>
          <p className="p">Find the perfect coach for your needs</p>
          <div className="button">
            <div className="primary" onClick={handleLogin}>
              <div className="title-2">Get Started</div>
            </div>
            <div className="seconday" onClick={handleSignUp}>
              <div className="title-2">Apply as a Coach</div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-2">
        <div className="frame-3">
          <div className="airbnb-search">
            <div className="attribute">
              <div className="attribute-2">Search</div>
              <p className="value">Search by sport, coach, level...</p>
            </div>
          </div>
          <div className="airbnb-search-2">
            <div className="attribute">
              <div className="attribute-2">Location</div>
              <p className="value">Where are you based ?</p>
            </div>
            <img className="img-2" alt="Chevron down" src={chevron_down_2} />
          </div>
          <div className="airbnb-search-2">
            <div className="attribute">
              <div className="attribute-2">Sport</div>
              <div className="value">Choose your sports</div>
            </div>
            <img className="img-2" alt="Chevron down" src={chevron_down_2} />
          </div>
          <div className="primary-2">
            <img
              className="img-2"
              alt="Vuesax linear search"
              src={linear_search_normal_1}
            />
            <div className="title-3">Search</div>
          </div>
        </div>
        <div className="frame-4"></div>
      </div>
      <Slider {...settings}>
        {lessonsFeedList?.map((lesson: ILessonsFeed) => {
          return (
            <div className="list" key={lesson.id}>
              <div className="row">
                <div className="card">
                  <div className="image-container">
                    <img
                      src={lesson?.image || default_img}
                      alt="image"
                      className="image"
                    />
                    <div className="title-wrapper">
                      <div className="title-4">${lesson.defaultPrice}</div>
                    </div>
                  </div>
                  <div className="text-content">
                    <p className="subtitle">{lesson.title}</p>
                    <div className="user">
                      <div className="avatar">
                        <div className="avatar-2" />
                        <div className="div-wrapper">
                          <div className="title-5">
                            {`${lesson.coach.firstName} ${lesson.coach.lastName}`}
                          </div>
                        </div>
                      </div>

                      {lesson.coach.totalRating > 0 && (
                        <div style={{ display: "flex", gap: "2px" }}>
                          {lesson.coach.totalRating % 1 !== 0.5
                            ? stars.slice(-lesson.coach.totalRating)
                            : stars
                                .slice(-lesson.coach.totalRating)
                                .concat(
                                  <img
                                    key={Math.random()}
                                    src={ic_half_star}
                                    alt="ic-half-star"
                                    width={9.81}
                                  />
                                )}
                        </div>
                      )}
                    </div>
                    <div className="frame-6">
                      <img
                        className="img-3"
                        alt="Vuesax linear"
                        src={vuesax_linear_calendar_7}
                      />
                      <div className="title-6">
                        {moment(lesson.startsAt).format(
                          TIME_FORMAT.MONTH_DATE_YEAR
                        )}
                      </div>
                      <img
                        className="img-3"
                        alt="Vuesax linear clock"
                        src={vuesax_linear_clock_5}
                      />
                      <div className="title-6">
                        {moment(lesson.startsAt).format(TIME_FORMAT.SHORT_TIME)}
                      </div>
                      <img
                        className="img-3"
                        alt="Vuesax linear timer"
                        src={vuesax_linear_timer_5}
                      />
                      <div className="title-6">
                        {convertToHourMinute(lesson.duration)}
                      </div>
                    </div>
                    <div className="frame-6">
                      <img
                        className="img-3"
                        alt="Vuesax linear"
                        src={vuesax_linear_location_5}
                      />
                      <p className="title-6">{lesson.location.name}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
      <div className="frame-7">
        <div className="frame-8">
          <div className="title-7">Key Features</div>
          <p className="subtitle-2">
            Lorem ipsum dolor sit amet consectetur. Dui mattis vulputate
            facilisis pharetra aliquet odio diam pharetra. Tempus auctor nisl
            volutpat risus posuere amet. Ultrices phasellus id porta enim
            tristique.
          </p>
        </div>
        <div className="frame-9">
          <div className="item">
            <div className="vuesax-linear-wrapper">
              <img
                className="img-2"
                alt="Vuesax linear"
                src={vuesax_linear_calendar_tick_1}
              />
            </div>
            <div className="frame-10">
              <div className="title-8">Lesson Scheduling</div>
              <p className="p">
                Coaches can easily update, track, and manage their preferred
                schedules using Kumu.
              </p>
            </div>
          </div>
          <div className="item-2">
            <div className="img-wrapper">
              <img
                className="img-2"
                alt="Vuesax linear tag"
                src={vuesax_linear_tag_user_1}
              />
            </div>
            <div className="frame-10">
              <div className="title-8">Client Marketplace</div>
              <p className="subtitle-3">
                Players can browse through coaches&#39; schedules to find and
                connect with the coach that best suits their needs.
              </p>
            </div>
          </div>
          <div className="item-2">
            <div className="img-wrapper">
              <img
                className="img-2"
                alt="Vuesax linear"
                src={vuesax_linear_calendar_10}
              />
            </div>
            <div className="frame-10">
              <div className="title-8">Shareable schedule</div>
              <p className="subtitle-3">
                Coaches can effortlessly share their complete schedules or
                specific lessons directly with players to streamline and
                facilitate lesson scheduling.
              </p>
            </div>
          </div>
          <div className="item-2">
            <div className="img-wrapper">
              <img
                className="img-2"
                alt="Vuesax linear coin"
                src={vuesax_linear_coin_1}
              />
            </div>
            <div className="frame-10">
              <div className="title-8">Easy Payment</div>
              <p className="subtitle-3">
                Through Kumu-Stripe integration, Coaches and Players can
                securely manage lesson payments.
              </p>
            </div>
          </div>
          <div className="item-2">
            <div className="img-wrapper">
              <img
                className="img-2"
                alt="Vuesax linear star"
                src={vuesax_linear_star_1}
              />
            </div>
            <div className="frame-10">
              <div className="title-8">Two way rating</div>
              <p className="subtitle-3">
                Coaches and players have the chance to offer feedback to one
                another through Kumu, promoting greater transparency and
                accountability.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="frame-11">
        <div className="title-7">How It Works</div>
        <div className="div-2">
          <div className="article">
            <div className="text-wrapper-3">.01</div>
            <img className="vector" alt="Vector" src={vector_601_8} />
            <div className="frame-10">
              <div className="frame-12">
                <div className="title-9">Coach</div>
              </div>
              <div className="title-10">Sign Up</div>
              <p className="subtitle-4">
                Coaches upload and manage their schedules within the Kumu
                application
              </p>
            </div>
          </div>
          <div className="article">
            <div className="text-wrapper-3">.02</div>
            <img className="vector" alt="Vector" src={vector_601_8} />
            <div className="frame-10">
              <div className="frame-12">
                <div className="title-9">Coach</div>
              </div>
              <div className="title-10">Manage Payments</div>
              <p className="subtitle-5">
                Coaches&#39; schedules will populate the Kumu marketplace or be
                shared directly with clientele
              </p>
            </div>
          </div>
          <div className="article">
            <div className="text-wrapper-3">.03</div>
            <img className="vector" alt="Vector" src={vector_601_8} />
            <div className="frame-10">
              <div className="frame-13">
                <div className="title-11">Client</div>
              </div>
              <div className="title-10"> Schedule Lessons</div>
              <p className="subtitle-5">
                Clients can search the Kumu marketplace for coach availability
                that best fits their needs
              </p>
            </div>
          </div>
          <div className="article">
            <div className="text-wrapper-3">.04</div>
            <img className="vector" alt="Vector" src={vector_601_8} />
            <div className="frame-10">
              <div className="frame-13">
                <div className="title-11">Client</div>
              </div>
              <div className="title-10">LESSONS REQUEST</div>
              <p className="subtitle-5">
                {" "}
                Clients can submit a request for specific lessons within the
                marketplace or when receiving invitations directly from coaches
              </p>
            </div>
          </div>
          <div className="article">
            <div className="text-wrapper-3">.05</div>
            <img className="vector" alt="Vector" src={vector_601_8} />
            <div className="frame-10">
              <div className="frame-12">
                <div className="title-9">Coaches</div>
              </div>
              <div className="title-10">Lessons review</div>
              <p className="subtitle-5">
                Coaches review lesson requests based on their existing calendars
              </p>
            </div>
          </div>
          <div className="article">
            <div className="text-wrapper-3">.06</div>
            <img className="vector" alt="Vector" src={vector_601_8} />
            <div className="frame-10">
              <div className="frame-14">
                <div className="title-9">Coach &amp; Client</div>
              </div>
              <div className="title-10">SUBMIT REVIEWS</div>
              <p className="subtitle-5">
                Coaches and Clients can leave reviews for each other
              </p>
            </div>
          </div>
        </div>
        <img className="rectangle-2" alt="Rectangle" src={rectangle_4469_1} />
      </div>
      <div className="frame-15">
        <div className="title-12">How It Works</div>
        <img className="union-4" alt="Union" src={union_13} />
        <div className="frame-16">
          <div className="frame-17">
            <div className="featured-icon">
              <img
                className="img-4"
                alt="Vuesax linear cup"
                src={vuesax_linear_cup_1}
              />
            </div>
            <div className="div-3">
              <div className="title-13">Improve your game</div>
              <p className="subtitle-6">
                Get the feedback and advice you need to grow and develop as an
                athelte
              </p>
            </div>
          </div>
          <div className="frame-17">
            <div className="featured-icon">
              <img
                className="img-4"
                alt="Vuesax linear"
                src={vuesax_linear_calendar_11}
              />
            </div>
            <div className="div-3">
              <div className="title-13">Learn a new skill</div>
              <p className="subtitle-6">
                Our experts can help you build the fundamentals you need to
                learn a new sport
              </p>
            </div>
          </div>
          <div className="frame-17">
            <div className="featured-icon">
              <img
                className="img-4"
                alt="Vuesax linear user"
                src={vuesax_linear_user_octagon_1}
              />
            </div>
            <div className="div-3">
              <p className="title-13">Get outside and stay active</p>
              <p className="subtitle-6">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                odio in et, lectus sit lorem id integer.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="frame-18">
        <div className="title-14">How It Works</div>
        <div className="frame-19">
          <div className="card-2">
            <div className="user">
              <div className="avatar">
                <div className="avatar-3" />
                <div className="div-wrapper">
                  <div className="title-15">John Smith</div>
                </div>
              </div>
              <img className="frame-5" alt="Frame" src={frame_427318817_15} />
            </div>
            <p className="title-16">
              This platform has significantly improved my coaching business.
              Highly recommended!
            </p>
          </div>
          <div className="card-2">
            <div className="user">
              <div className="avatar">
                <div className="avatar-4" />
                <div className="div-wrapper">
                  <div className="title-15">John Smith</div>
                </div>
              </div>
              <img className="frame-5" alt="Frame" src={frame_427318817_15} />
            </div>
            <p className="title-16">
              Scheduling lessons has never been easier. It saves me so much
              time!
            </p>
          </div>
          <div className="card-2">
            <div className="user">
              <div className="avatar">
                <div className="avatar-5" />
                <div className="div-wrapper">
                  <div className="title-15">John Smith</div>
                </div>
              </div>
              <img className="frame-5" alt="Frame" src={frame_427318817_15} />
            </div>
            <p className="title-16">
              I love the performance metrics feature. It helps me better
              understand my athletes&#39; progress.
            </p>
          </div>
          <div className="card-2">
            <div className="user">
              <div className="avatar">
                <div className="avatar-6" />
                <div className="div-wrapper">
                  <div className="title-15">John Smith</div>
                </div>
              </div>
              <img className="frame-5" alt="Frame" src={frame_427318817_15} />
            </div>
            <p className="title-16">
              I love the performance metrics feature. It helps me better
              understand my athletes&#39; progress.
            </p>
          </div>
          <div className="card-2">
            <div className="user">
              <div className="avatar">
                <div className="avatar-7" />
                <div className="div-wrapper">
                  <div className="title-15">John Smith</div>
                </div>
              </div>
              <img className="frame-5" alt="Frame" src={frame_427318817_15} />
            </div>
            <p className="title-16">
              I love the performance metrics feature. It helps me better
              understand my athletes&#39; progress.
            </p>
          </div>
        </div>
      </div>

      <div className="section-3">
        <div className="frame-8">
          <div className="title-7">Get in touch</div>
          <p className="subtitle-7">
            Lorem ipsum dolor sit amet consectetur. Dui mattis vulputate
            facilisis pharetra aliquet odio diam pharetra. Tempus auctor nisl
            volutpat risus posuere amet. Ultrices phasellus enim tristique.
          </p>
        </div>
        <div className="frame-20">
          <img className="US-divided" alt="Us divided" src={us_divided_1} />
          <div className="content-wrapper">
            <div className="form-wrapper">
              <div className="div-4">
                <div className="form-fields">
                  <div className="input-field">
                    <div className="div-5">
                      <div className="div-5">
                        <input
                          className="label"
                          placeholder="First name"
                          type="text"
                        />
                        <div className="input">
                          <input
                            className="text"
                            id="input-2"
                            placeholder="First name"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="input-field">
                    <div className="div-5">
                      <div className="div-5">
                        <input
                          className="label"
                          placeholder="Last name"
                          type="text"
                        />
                        <div className="input">
                          <input
                            className="text"
                            id="input-4"
                            placeholder="Last name"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="input-field">
                    <div className="div-5">
                      <div className="div-5">
                        <input
                          className="label"
                          placeholder="Email"
                          type="email"
                        />
                        <div className="input">
                          <input
                            className="text"
                            id="input-6"
                            placeholder="you@company.com"
                            type="email"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="textarea-input-field">
                    <div className="div-6">
                      <div className="div-6">
                        <div className="label-2">Message</div>
                        <div className="input-2" />
                      </div>
                    </div>
                  </div>
                  <div className="checkbox">
                    <div className="checkbox-2" />
                    <p className="text-2">
                      <span className="span">You agree to our friendly </span>
                      <span className="text-wrapper-4">privacy term</span>
                      <span className="span">.</span>
                    </p>
                  </div>
                </div>
                <div className="div-2">
                  <button className="button-base-wrapper">
                    <div className="button-base">
                      <div className="text-3">Send message</div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-4">
        <div className="content-2">
          <div className="div-2">
            <div className="heading">Join 2,000+ subscribers</div>
            <p className="supporting-text">
              Stay in the loop with everything you need to know.
            </p>
          </div>
          <div className="div-2">
            <div className="input-field">
              <div className="div-3">
                <div className="div-5">
                  <div className="input-3">
                    <input
                      className="content-3"
                      placeholder="Enter your email"
                      type="email"
                    />
                  </div>
                </div>
                <p className="hint-text">
                  <span className="text-wrapper-5">
                    We care about your data in our{" "}
                  </span>
                  <span className="text-wrapper-4">privacy term</span>
                </p>
              </div>
            </div>
            <button className="button-base-wrapper">
              <button className="button-base-2">
                <div className="text-4">Subscribe</div>
              </button>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
