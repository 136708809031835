import React from "react";
import HomeDesktop from "../../components/HomeDesktop/index";
import HomeMobile from "../../components/HomeMobile";
import { useWindowWidth } from "../../breakpoints";

import "./style.css";

const Home = (): JSX.Element => {
  const screenWidth = useWindowWidth();

  return (
    <>
      {screenWidth < 900 && <HomeMobile />}
      {screenWidth >= 900 && <HomeDesktop />}
    </>
  );
};

export default Home;
