export function convertToHourMinute(timeInMinutes) {
    if (timeInMinutes < 60) {
      return timeInMinutes + 'm';
    } else {
      var hours = Math.floor(timeInMinutes / 60);
      var minutes = timeInMinutes % 60;
      if (minutes === 0) {
        return hours + 'h';
      } else {
        return hours + 'h ' + minutes + 'm';
      }
    }
  }
  